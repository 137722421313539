<template>
    <div class="api-service">
        <a-row :gutter="2" type="flex" justify="space-between">
            <a-col :span="5">
                 <a-menu
                     :default-selected-keys="['1']"
                     :default-open-keys="['goods']"
                     mode="inline"
                     theme="light"
                 >
                                  <a-sub-menu  v-for="item in menus" :key = "item.key" @click="changeMenu">
                                    <span slot = "title"><span>{{item.name}}</span></span>
                                    <a-menu-item v-for="child in item.children" :key = "child.key">
                                      {{ child.name }}
                                    </a-menu-item>
                                  </a-sub-menu>
                                </a-menu>

            </a-col>
            <a-col :span="18">
                <div class="api-test-content">
                    <a-row style="margin-bottom: 16px;"><a-col>{{currentContent.title}}</a-col></a-row>
                    <a-row style="margin-bottom: 16px;"><a-col>{{currentContent.uri}}</a-col></a-row>
                    <a-row style="margin-bottom: 16px;"><a-col>{{currentContent.desc}}</a-col></a-row>
                    <a-row style="margin-bottom: 16px;">
                        <a-col class="api-title">请求地址</a-col>
                        <a-col>
                            <a-table :columns="[{title:'环境', key:'env',dataIndex:'env' }, {title:'请求地址', key:'address', dataIndex: 'address'}]" :data-source="[{env:'正式环境', address:baseUrl+currentContent.uri}, {env:'请求类型', address: currentContent.method}]" :pagination="false"></a-table>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom: 16px;">
                        <a-col class="api-title">请求头</a-col>
                        <a-col>
                            <a-table :columns="headerColumns" :data-source="header" :pagination="false"></a-table>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom: 16px;">
                        <a-col class="api-title">请求参数</a-col>
                        <a-col>
                            <a-table :columns="headerColumns" :data-source="currentContent.params" :pagination="false"></a-table>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom: 16px;">
                        <a-col style="margin-bottom: 16px;">请求示例</a-col>
                        <a-col>
                            <a-textarea v-model="currentContent.paramsDemo" rows="6"></a-textarea>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom: 16px;">
                        <a-col class="api-title">响应参数</a-col>
                        <a-col>
                            <a-table :columns="headerColumns" :data-source="baseResponse" :pagination="false"></a-table>
                        </a-col>
                    </a-row>
                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
export default {
    name: "api-service",
    data(){
        return {
            baseUrl:'https://api.myhbei.com/supplier',
            headerColumns:[
                {title:'参数名称', key:'name', dataIndex:'name'},
                {title:'中文参数名', key:'name_zn', dataIndex:'name_zn', width:'120px'},
                {title:'参数类型', key:'type', dataIndex:'type'},
                {title:'是否必填', key:'required', dataIndex:'required'},
                {title:'说明', key:'remark', dataIndex:'remark'},
            ],
            header:[
                {name:'appKey', name_zn:'appkey', type:'string', required: 'true', remark:''},
                {name:'sign', name_zn:'密钥', type:'String', required: 'true', remark:''},
                {name:'timeStamp', name_zn:'当前时间', type:'String', required: 'true', remark:'当前时间毫秒数'},
                {name:'randomNumber', name_zn:'随机数', type:'String', required: 'true', remark:'随机生成数字'},
                // {name:'version', name_zn:'版本号', type:'String', required: true, remark:'版本号'},
            ],
            baseResponse:[
                {name:'msg', name_zn:'消息', type:'string', required: 'false', remark:''},
                {name:'success', name_zn:'成功/失败', type:'boolean', required: 'true', remark:''},
                {name:'status', name_zn:'状态码', type:'integer', required: 'true', remark:''},
                {name:'data', name_zn:'业务数据返回', type:'array', required: 'true', remark:'', children:[]},
            ],
            menus:[
                {name:'商品', key: 'goods', children:[
                        {name:'获取分类信息',key: '1',},
                        {name:'查询商品库SPUID列表',key: '2',},
                        {name:'查询SPU商品详情',key: '3',},
                        // {name:'查询SKU规格信息',key: '4',},
                        {name:'查询商品运费',key: '5',},
                        {name:'查询商品价格',key: '6',},
                        // {name:'查询商品库存',key: '7',},
                    ]},
                {name:'订单', key: 'order', children:[
                        {name:'获取省市区地址',key: '8',},
                        {name:'创建订单',key: '9',},
                        {name:'根据外部订单号查询订单详情列表',key: '10',},
                        {name:'查询订单详情',key: '11',},
                        {name:'查询物流信息',key: '12',},
                        {name:'确认收货',key: '13',},
                        {name:'售后申请',key: '14',},
                        {name:'取消售后申请',key: '15',},
                        {name:'查询售后单详细信息',key: '16',},
                    ]},
                {name:'消息', key:'message', children:[
                        {name:'删除消息', key:'17'},
                        {name:'查询消息池表', key:'18'},
                    ]}
            ],
            currentContent:{},
            content:{
                1: {
                    title: '获取分类信息 1.0版',
                    uri: '/v1/goods/getCategories',
                    desc:'获取分类信息。 1.输入默认值0查询所有一级分类（即入参 "id": 0） 2.输入一级分类id，查询对应二级id',
                    method:'POST',
                    params:[
                        {name:'id', name_zn:'上级类目ID一级类目的上级为0', type:'integer', required: 'false', remark:''}
                    ],
                    paramsDemo:'{"id":0}',
                    responseData:[
                        {name:'id', name_zn:'ID', type:'integer', required: 'true', remark:''},
                        {name:'pid', name_zn:'上级类目ID一级类目的上级为0', type:'integer', required: 'true', remark:''},
                        {name:'name', name_zn:'名称', type:'string', required: 'true', remark:''},
                    ],
                },
                2: {
                    title: '查询商品库SPUID列表 1.0版',
                    uri: '/v1/goods/spuList',
                    desc:'根据分销商ID，查询该分销商商品库SPUID列表。如果需要查询所有商品，参数只填{}即可。 注意： 1、spuPageQueryDTO不是参数，是对象的集合，不能作为入参的参数 2、Content-Type设置为application/json 3、请求时间与服务器时间相差不能超过5秒',
                    method:'POST',
                    params:[
                        {name:'page', name_zn:'页码', type:'integer', required: 'false', remark:'默认为1'}
                    ],
                    paramsDemo:'{"page":1}',
                    responseData:[
                        {name:'list', name_zn:'spu列表', type:'array', required: 'true', remark:'', children:[
                                {name:'spu_id', name_zn:'spu id', type:'integer', required: 'true', remark:''},
                                {name:'type', name_zn:'商品类型', type:'string', required: 'true', remark:''},
                            ]},
                        {name:'total', name_zn:'总数', type:'integer', required: 'true', remark:''},
                    ],
                },
                3:{
                    title:'查询SPU商品详情 1.0版\n',
                    uri: '/v1/goods/detail',
                    desc:'根据SPUID，查询获取商品类型、商品分类、商品条码、商品名称、品牌、商品属性列表、是否跨境商品、总库存、是否包邮、是否限制销售范围、允许销售区域、商品轮播图、商品主图视频、商品详情图、商品销售状态',
                    method:'POST',
                    params:[
                        {name:'spu_id', name_zn:'商品spu_id', type:'integer', required: 'true', remark:''},
                        {name:'goods_type', name_zn:'商品类型', type:'integer', required: 'true', remark:''}
                    ],
                    paramsDemo:'{\n' +
                        '    "spu_id": ""\n' +
                        '    "goods_type": ""\n' +
                        '}',
                    responseData:[
                        {name:'spu_id', name_zn:'商品spu_i', type:'integer', required: 'true', remark:''},
                        {name:'cate1_id', name_zn:'一级分类id', type:'integer', required: 'true', remark:''},
                        {name:'cate2_id', name_zn:'二级分类id', type:'string', required: 'true', remark:''},
                        {name:'title', name_zn:'商品标题', type:'string', required: 'true', remark:''},
                        {name:'m_title', name_zn:'商品副标题', type:'string', required: 'true', remark:''},
                        {name:'logo', name_zn:'商品主图', type:'string', required: 'true', remark:''},
                        {name:'specs', name_zn:'商品属性', type:'array', required: 'true', remark:'', children:[
                                {name:'name', name_zn:'属性名称', type:'string', required:'true', remark:''},
                                {name:'list', name_zn:'属性组', type:'array', required:'true', remark:'', children:[
                                        {name:'name', name_zn:'规格名', type:'string', required:'true'},
                                    ]}
                            ]},
                        {name:'sku_list', name_zn:'sku规格列表', type:'array', required: 'true', remark:'', children: [
                                {name:'sku', name_zn:'sku编码', type:'string', required: 'true', remark: ''},
                                {name:'sku_thumb', name_zn:'sku图标', type:'string', required: 'true', remark: ''},
                                {name:'base_price', name_zn:'分销价', type:'number', required: 'true', remark: ''},
                                {name:'cost', name_zn:'市场价', type:'number', required: 'true', remark: ''},
                                {name:'price_market', name_zn:'建议零售价', type:'number', required: 'true', remark: ''},
                                {name:'stock', name_zn:'库存', type:'integer', required: 'true', remark: ''},
                                {name:'status', name_zn:'销售状态', type:'integer', required: 'true', remark: '商品状态：0、已上架1、已下架'},
                            ]}
                    ],
                },
                5:{
                    title:'查询商品运费 1.0版',
                    uri: '/v1/goods/calculateFreight',
                    desc:'根据商品详情页返回的商品是否包邮，若不包邮的商品，根据SPUID（支持多个）及收货区域，获取运费金额',
                    method:'POST',
                    params:[
                        {name:'goodsList', name_zn:'商品列表', type:'array', required: 'true', remark:'', children:[
                                {name:'spu_id', name_zn:'商品编号', type:'integer', required:'true', remark:''},
                                {name:'quantity', name_zn:'商品数量', type:'integer', required:'true', remark:''},
                                {name:'goods_type', name_zn:'商品类型', type:'string', required:'true', remark:''},
                            ]},
                        {name:'city_id', name_zn: '城市编号id', type:'integer', required: 'true', remark: ''}
                    ],
                    paramsDemo:'{\n' +
                        '                    "goodsList": [\n' +
                        '                        {\n' +
                        '                            "quantity": 0,\n' +
                        '                            "spu_id": 0\n' +
                        '                            "type": "cloud"\n' +
                        '                        }\n' +
                        '                    ],\n' +
                        '                    "city_id": ""\n' +
                        '                }',
                    responseData:'',
                },
                6:{
                    title:'查询商品价格 1.0版',
                    uri: '/v1/goods/findSkuSalePrice',
                    desc:'根据SKUID，查询商品SKU价格（采购价、建议零售价、市场价）',
                    method:'POST',
                    params:[
                        {name:'goodsList', name_zn:'商品列表', type:'array', required: 'true', remark:'', children:[
                                {name:'spu_id', name_zn:'商品编号', type:'integer', required:'true', remark:''},
                                {name:'quantity', name_zn:'商品数量', type:'integer', required:'true', remark:''},
                                {name:'goods_type', name_zn:'商品类型', type:'string', required:'true', remark:''},
                            ]},
                        {name:'city_id', name_zn: '城市编号id', type:'integer', required: 'true', remark: ''}
                    ],
                    paramsDemo:'{\n' +
                        '                    "skuIds": ""\n' +
                        '                }',
                    responseData:[
                        {name:'sku', name_zn:'sku编码', type:'string', required: 'true', remark: ''},
                        {name:'sku_thumb', name_zn:'sku图标', type:'string', required: 'true', remark: ''},
                        {name:'base_price', name_zn:'分销价', type:'number', required: 'true', remark: ''},
                        {name:'cost', name_zn:'市场价', type:'number', required: 'true', remark: ''},
                        {name:'price_market', name_zn:'建议零售价', type:'number', required: 'true', remark: ''},
                        {name:'stock', name_zn:'库存', type:'integer', required: 'true', remark: ''},
                        {name:'status', name_zn:'销售状态', type:'integer', required: 'true', remark: '商品状态：0、已上架1、已下架'},
                    ],
                },
                8:{
                    title:'获取省市区地址 1.0版',
                    uri: '/v1/region/getRegionByCodeOpen',
                    desc:'获取地址库地址信息： 查询一级地区，不传regionCode则获取全量一级地区（即regionCode入参为空）；查询二级地区，传一级地区的regionCode；查询三级地区，传二级地区的regionCode；查询四级地区，传三级地区的regionCode',
                    method:'POST',
                    params:[
                        {name:'regionCode', name_zn: '区域编码', type:'string', required: 'false', remark: ''}
                    ],
                    paramsDemo:'{\n' +
                        '                    "regionCode": ""\n' +
                        '                }',
                    responseData:[
                        {name:'regionFullCodes', name_zn:'全部编码', type:'string', required: 'false', remark: ''},
                        {name:'parentName', name_zn:'上级名称', type:'string', required: 'false', remark: ''},
                        {name:'regionCode', name_zn:'区域编码', type:'integer', required: 'false', remark: ''},
                        {name:'initial', name_zn:'首字母', type:'string', required: 'false', remark: ''},
                        {name:'parentCode', name_zn:'上级编码', type:'integer', required: 'false', remark: ''},
                        {name:'latitude', name_zn:'纬度', type:'string', required: 'false', remark: ''},
                        {name:'regionFullName', name_zn:'区域全称', type:'string', required: 'false', remark: ''},
                        {name:'name', name_zn:'区域名称', type:'string', required: 'false', remark: ''},
                        {name:'id', name_zn:'ID', type:'integer', required: 'false', remark: ''},
                        {name:'regionLevel', name_zn:'行政级别', type:'integer', required: 'false', remark: ''},
                        {name:'longitude', name_zn:'经度', type:'string', required: 'false', remark: ''},
                    ],
                },
                9:{
                    title:'创建订单 1.0版',
                    uri: '/v1/order/submitOrder',
                    desc:'创建订单时会校验分销商预付款是否足够支付，若充足则直接下单成功并扣除预付款，若不足下单失败。 注意： 1、orderSource参数传：1.开放平台； 2、Content-Type设置为application/json； 3、请求时间与服务器时间相差不能超过5秒',
                    method:'POST',
                    params:[
                        {name:'orderSource', name_zn: '订单来源', type:'integer', required: 'true', remark: '1 开放平台'},
                        {name:'goodsList', name_zn: '商品列表', type:'array', required: 'true', remark: '', children:[
                                {name:'spu_id', name_zn:'商品编号', type:'integer', required:'true', remark:''},
                                {name:'sku', name_zn:'sku编号', type:'string', required:'true', remark:''},
                                {name:'quantity', name_zn:'购买数量', type:'integer', required:'true', remark:''},
                                {name:'goods_type', name_zn:'商品类型', type:'string', required:'true', remark:''},
                            ]},
                        {name:'shipArea', name_zn:'收货地区', type:'string', required:'true', remark:'例如:辽宁省,沈阳市,铁西区,三级地址用英文逗号分隔'},
                        {name:'shipName', name_zn:'收货联系人姓名', type:'string', required:'true', remark:''},
                        {name:'shipAddress', name_zn:'收货地址', type:'string', required:'true', remark:'详细地址，如创业路东'},
                        {name:'shipMobile', name_zn:'收货人联系电话', type:'string', required:'true', remark:''},
                        {name:'shipAreaCode', name_zn:'收货地区编码', type:'string', required:'true', remark:'例如:省ID,市ID,区ID,三级地址用英文逗号分隔'},
                        {name:'outOrderSn', name_zn: '外部订单号'}
                    ],
                    paramsDemo:'{\n' +
                        '                    "orderSource": 0,\n' +
                        '                    "goodsList": [\n' +
                        '                        {\n' +
                        '                            "goodsQty": "",\n' +
                        '                            "skuId": ""\n' +
                        '                        }\n' +
                        '                    ],\n' +
                        '                    "buyerName": "",\n' +
                        '                    "shipArea": "",\n' +
                        '                    "shipName": "",\n' +
                        '                    "shipAddress": "",\n' +
                        '                    "shipMobile": "",\n' +
                        '                    "outOrderSn": "",\n' +
                        '                    "shipAreaCode": ""\n' +
                        '                }',
                    responseData:[
                        {name:'orderStatusName', name_zn:'订单状态', type:'string', required: 'false', remark: ''},
                        {name:'totalAmount', name_zn:'订单金额', type:'string', required: 'false', remark: ''},
                        {name:'orderSn', name_zn:'订单编号', type:'string', required: 'false', remark: ''},
                        {name:'orderStatus', name_zn:'订单状态', type:'integer', required: 'false', remark: ''},
                    ],
                },
                10:{
                    title:'根据外部订单号查询订单详情列表 1.0版',
                    uri: '/v1/order/getOrderDetailList',
                    desc:'根据外部订单号查询订单详情列表',
                    method:'POST',
                    params:[
                        {name:'outOrderSn', name_zn: '外部订单号', type:'string', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "thirdOrderSn": ""\n' +
                        '}',
                    responseData:[
                        {name:'totalGoodsPrice', name_zn:'商品金额', type:'string', required: 'true', remark: ''},
                        {name:'orderSn', name_zn:'订单编号', type:'string', required: 'true', remark: ''},
                        {name:'payTime', name_zn:'支付时间', type:'string', required: 'true', remark: ''},
                        {name:'orderStatus', name_zn:'订单状态', type:'integer', required: 'true', remark: ''},
                        {name:'orderStatusDesc', name_zn:'订单状态描述', type:'string', required: 'true', remark: ''},
                        {name:'payAmount', name_zn:'支付金额', type:'integer', required: 'true', remark: ''},
                        {name:'confirmReceiveTime', name_zn:'确认收货时间', type:'string', required: 'false', remark: ''},
                        {name:'outOrderSn', name_zn:'外部订单号', type:'integer', required: 'false', remark: ''},
                        {name:'deliveryPrice', name_zn:'快递费用', type:'integer', required: 'true', remark: ''},
                        {name:'totalOrderPrice', name_zn:'总订单费用', type:'integer', required: 'true', remark: ''},
                        {name:'sendTime', name_zn:'发货时间', type:'integer', required: 'false', remark: ''},
                        {name:'goodsList', name_zn:'商品', type:'array', required: 'true', remark: '', children:[
                                {name:'goods_id', name_zn:'商品spuid', type:'integer', required: 'false', remark: ''},
                                {name:'goods_title', name_zn:'商品名称', type:'string', required: 'false', remark: ''},
                                {name:'number_goods', name_zn:'商品数', type:'integer', required: 'false', remark: ''},
                                {name:'goods_logo', name_zn:'商品图标', type:'string', required: 'false', remark: ''},
                                {name:'goods_spec', name_zn:'商品规格', type:'string', required: 'false', remark: ''},
                                {name:'price_real', name_zn:'商品金额', type:'string', required: 'false', remark: ''},
                                {name:'price_express', name_zn:'快递费用', type:'string', required: 'false', remark: ''},
                            ]},
                    ],
                },
                11:{
                    title:'查询订单详情 1.0版',
                    uri: '/v1/order/getOrderDetailList',
                    desc:'根据订单号查询订单详细参数',
                    method:'POST',
                    params:[
                        {name:'orderSn', name_zn: '订单号', type:'string', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "orderSn": ""\n' +
                        '}',
                    responseData:[
                        {name:'totalGoodsPrice', name_zn:'商品金额', type:'string', required: 'true', remark: ''},
                        {name:'orderSn', name_zn:'订单编号', type:'string', required: 'true', remark: ''},
                        {name:'payTime', name_zn:'支付时间', type:'string', required: 'true', remark: ''},
                        {name:'orderStatus', name_zn:'订单状态', type:'integer', required: 'true', remark: ''},
                        {name:'orderStatusDesc', name_zn:'订单状态描述', type:'string', required: 'true', remark: ''},
                        {name:'payAmount', name_zn:'支付金额', type:'integer', required: 'true', remark: ''},
                        {name:'confirmReceiveTime', name_zn:'确认收货时间', type:'string', required: 'false', remark: ''},
                        {name:'outOrderSn', name_zn:'外部订单号', type:'integer', required: 'false', remark: ''},
                        {name:'deliveryPrice', name_zn:'快递费用', type:'integer', required: 'true', remark: ''},
                        {name:'totalOrderPrice', name_zn:'总订单费用', type:'integer', required: 'true', remark: ''},
                        {name:'sendTime', name_zn:'发货时间', type:'integer', required: 'false', remark: ''},
                        {name:'goodsList', name_zn:'商品', type:'array', required: 'true', remark: '', children:[
                                {name:'goods_id', name_zn:'商品spuid', type:'integer', required: 'false', remark: ''},
                                {name:'goods_title', name_zn:'商品名称', type:'string', required: 'false', remark: ''},
                                {name:'number_goods', name_zn:'商品数', type:'integer', required: 'false', remark: ''},
                                {name:'goods_logo', name_zn:'商品图标', type:'string', required: 'false', remark: ''},
                                {name:'goods_spec', name_zn:'商品规格', type:'string', required: 'false', remark: ''},
                                {name:'price_real', name_zn:'商品金额', type:'string', required: 'false', remark: ''},
                                {name:'price_express', name_zn:'快递费用', type:'string', required: 'false', remark: ''},
                            ]},
                    ],
                },
                12: {
                    title: '查询物流信息 1.0版',
                    uri: '/v1/order/getOrderDetailList',
                    desc: '查询物流信息',
                    method: 'POST',
                    params: [
                        {name: 'orderSn', name_zn: '订单号', type: 'string', required: 'true', remark: ''},
                    ],
                    paramsDemo: '{\n' +
                        '    "orderSn": ""\n' +
                        '}',
                    responseData: [
                        {name: 'context', name_zn: '描述', type: 'string', required: 'true', remark: ''},
                        {name: 'time', name_zn: '时间', type: 'string', required: 'true', remark: ''},
                        {name: 'status', name_zn: '状态', type: 'string', required: 'true', remark: ''},
                    ],
                },
                13:{
                    title:'确认收货 1.0版',
                    uri: '/v1/order/confirmReceive',
                    desc:'订单确认收货',
                    method:'POST',
                    params:[
                        {name:'orderSn', name_zn: '订单号', type:'string', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "orderSn": ""\n' +
                        '}',
                    responseData:'',
                },
                14:{
                    title:'售后申请 1.0版',
                    uri: '/v1/afterSale/applyRefund',
                    desc:'发起商品售后类型退款或退货退款',
                    method:'POST',
                    params:[
                        {name:'orderSn', name_zn: '订单号', type:'string', required: 'true', remark: ''},
                        {name:'refund_desc', name_zn: '售后原因描述', type:'string', required: 'true', remark: ''},
                        {name:'refund_image', name_zn: '退款上传图片URL集合', type:'array', required: 'false', remark: ''},
                        {name:'reason_id', name_zn: '退款原因ID, 请参考文档中心-订单售后申请原因说明文档', type:'integer', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "orderSn": ""\n' +
                        '}',
                    responseData:[
                        {name:'refund_no', name_zn: '退款单号', type:'string', required: 'true', remark: ''},
                    ],
                },
                15:{
                    title:'取消售后申请 1.0版',
                    uri: '/v1/afterSale/cancelRefund',
                    desc:'取消售后申请',
                    method:'POST',
                    params:[
                        {name:'refund_no', name_zn: '订单号', type:'string', required: 'true', remark: ''},
                        {name:'cancel_desc', name_zn: '取消原因描述', type:'string', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "refund_no": "",\n' +
                        '    "cancel_desc": ""\n' +
                        '}',
                    responseData:'',
                },
                16:{
                    title:'售后详情 1.0版',
                    uri: '/v1/afterSale/cancelRefund',
                    desc:'售后详情',
                    method:'POST',
                    params:[
                        {name:'refund_no', name_zn: '订单号', type:'string', required: 'true', remark: ''},
                    ],
                    paramsDemo:'{\n' +
                        '    "refund_no": ""\n' +
                        '}',
                    responseData:[
                        {name:'refund_no', name_zn: '售后单号', type:'string', required: 'true', remark: ''},
                        {name:'refund_state', name_zn: '售后状态', type:'string', required: 'true', remark: ''},
                        {name:'refund_price', name_zn: '退款金额', type:'string', required: 'true', remark: ''},
                        {name:'refund_desc', name_zn: '售后描述', type:'string', required: 'true', remark: ''},
                        {name:'refund_image', name_zn: '售后图片', type:'string', required: 'true', remark: ''},
                    ],
                },
                17:{
                    title:'删除消息 1.0版',
                    uri: '/v1/message/remove',
                    desc:'删除已接收消息',
                    method:'POST',
                    params:[
                        {name:'enumNum', name_zn: '消息枚举值', type:'string', required: 'true', remark: '消息枚举值：1-订单状态变更,2-订单发货，3-售后订单状态变更，4-商品信息、状态变更通知,5-商品删除通知,6-商品规格删除通,7-商品规格变更通知,8-选品库商品删除通知,9-选品库商品添加通知,10-添加选品库商品通知,11-删除选品库商品通知,12-选品库商品价格变更通知，20-创建订单'},
                        {name:'id', name_zn: '消息id', type:'string', required: 'true', remark: 'id(唯一标识)多个逗号(如:1,2,3)'},
                    ],
                    paramsDemo:'{\n' +
                        '    "enumNum": 0,\n' +
                        '    "id": ""\n' +
                        '}',
                    responseData: 'true',
                },
                18:{
                    title:'查询消息池表 1.0版',
                    uri: '/v1/message/getList',
                    desc:'获取消息池消息列表。注意：目前消息接口最多显示1000条记录，请及时删除无用记录',
                    method:'POST',
                    params:[
                        {name:'enumNum', name_zn: '消息枚举值', type:'integer', required: 'false', remark: '消息枚举值：1-订单状态变更,2-订单发货，3-售后订单状态变更，4-商品信息、状态变更通知,5-商品删除通知,6-商品规格删除通,7-商品规格变更通知,8-选品库商品删除通知,9-选品库商品添加通知,10-添加选品库商品通知,11-删除选品库商品通知,12-选品库商品价格变更通知，20-创建订单'},
                    ],
                    paramsDemo:'{\n' +
                        '    "enumNum": ""\n' +
                        '}',
                    responseData: [
                        {name: 'created_at', name_zn:'创建时间', type:'string', required:'true', remark:''},
                        {name: 'enumNum', name_zn:'消息枚举值', type:'integer', required:'true', remark:''},
                        {name: 'id', name_zn:'消息id', type:'integer', required:'true', remark:''},
                        {name: 'content', name_zn:'消息内容', type:'string', required:'true', remark:''},
                    ],
                }
            },
        }
    },
    created() {
        this.currentContent = this.content[1];
        this.baseResponse[3].children = this.currentContent.responseData;
    },
    methods:{
        changeMenu(item){
            this.currentContent = this.content[item.key];
            this.baseResponse[3].children = this.currentContent.responseData;
        }
    }
}
</script>

<style lang="less" scoped>
.api-test-content{
    background-color: #fff;
    padding: 27px;
}
.api-title{
    font-size: 18px;
    color: #333;
    padding-bottom: 12px;
}
</style>